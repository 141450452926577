import { AnimationName } from '../components/AnimatedImage/AnimatedImage';

/**
 * Important!!!
 * If you're returning an animation, you must set proper background color.
 */

export const getHeaderAnimationName = (): AnimationName | null => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();

  const start = new Date(year, 11, 15); // Current year, December, 15th
  const end = new Date(year, 1, 1); // Cuurent Year, February, 1st

  if (currentDate >= start || currentDate <= end){
    return 'winterBanner';
  }

  return null;
};
