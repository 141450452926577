import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Stylesheet } from '../../interfaces/Stylesheet';
import { Customer, CustomerFormFieldErrors } from '../../interfaces/Customer';
import { SaveStatus } from '../../pages/Customer/interfaces/Customer';
import Alert from '../Alert/Alert';
import FormElement, { FormChangeEvent } from '../Form/FormElement';
import style from './PersonData.module.scss';

export interface PersonDataProps {
  stylesheet: Stylesheet;
  customer: Customer;
  onFieldChange: <T extends keyof Customer>(value: Customer[T], fieldName: T) => void;
  isLoading: boolean;
  saveStatus: SaveStatus;
  fieldErrors: CustomerFormFieldErrors;
}

const PersonData: FC<PersonDataProps> = ({ stylesheet, customer, onFieldChange, isLoading, saveStatus, fieldErrors }) => {
  const { t } = useTranslation();
  const { initials, middleName, lastName, phoneNumber, mobileNumber, email } = customer;

  const onChange = (event: FormChangeEvent): void => {
    onFieldChange(event.target.value, event.target.name as keyof Customer);
  };

  const isDisabled = (field: string): boolean => {
    return stylesheet.customerSection.editFields.indexOf(field) === -1;
  };

  return (
    <div className="col-sm-12 col-md-12 col-lg-6">
      <h2>{t('form:personalDetails')}</h2>
      <form className={style.form}>
        <FormElement
          type="text"
          name="initials"
          value={initials || ''}
          onChange={onChange}
          split
          disabled={isDisabled('initials')}
          label={t('form:initials')}
        />
        <FormElement
          type="text"
          name="middleName"
          value={middleName || ''}
          onChange={onChange}
          split
          disabled={isDisabled('middleName')}
          label={t('form:middleName')}
        />
        <FormElement
          type="text"
          name="lastName"
          value={lastName || ''}
          onChange={onChange}
          disabled={isDisabled('lastName')}
          label={t('form:lastName')}
        />
        <FormElement
          type="text"
          name="phoneNumber"
          value={phoneNumber || ''}
          onChange={onChange}
          split
          disabled={isDisabled('phoneNumber')}
          label={t('form:phoneNumber')}
          error={fieldErrors.phoneNumber}
        />
        <FormElement
          type="text"
          name="mobileNumber"
          value={mobileNumber || ''}
          onChange={onChange}
          split
          disabled={isDisabled('mobileNumber')}
          label={t('form:mobileNumber')}
          error={fieldErrors.mobileNumber}
        />
        <FormElement
          type="text"
          name="email"
          value={email || ''}
          onChange={onChange}
          disabled={isDisabled('email')}
          label={t('form:email')}
          error={fieldErrors.email}
        />
      </form>

      {!!(isLoading || saveStatus.success || saveStatus.error) && (
        <Alert
          icon={saveStatus.error ? 'error' : undefined}
          text={isLoading ? t('alert:savingDetails') : saveStatus.error ? t('alert:saveError') : t('alert:saveSuccess')}
        />
      )}
    </div>
  );
};

export default PersonData;
