import React, { FC } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import style from './PreFooter.module.scss';
import BusinessReviews from '../BusinessReviews/BusinessReviews';
import WhatsAppLink from '../WhatsAppLink/WhatsAppLink';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import { Stylesheet } from '../../interfaces/Stylesheet';
import { FooterSectionColumn } from './FooterSectionColumn';
import ContentfulService from '../../services/ContentfulService';

interface PreFooterProps {
  stylesheet: Stylesheet;
}

const ICON_SIZE = 16;

const PreFooterComponent: FC<PreFooterProps> = ({ stylesheet }) => {
  const { t } = useTranslation();
  const { contact } = stylesheet;

  const footerSectionContainerStyles = classnames('row', style.container);

  return (
    <div className={footerSectionContainerStyles}>
      <FooterSectionColumn title={t('footer:title')}>
        <BusinessReviews />
      </FooterSectionColumn>

      <FooterSectionColumn title={t('footer:questions')}>
        {!!contact.whatsappNumber && (
          <>
            <h3>{t('contact:chatWithWhatsApp')}</h3>
            <WhatsAppLink contactNumber={contact.whatsappNumber} />
          </>
        )}
        {ContentfulService.getContentfulValueWithDefault(contact.showPhoneNumberInFooter, true) && (
          <>
            <h3>{t('footer:call')}</h3>
            <span className={style.footerSectionItem}>
              <MaterialIcon name="phone" size={ICON_SIZE} />
              <p>
                <a href={`tel:${contact.serviceDeskNumber}`}>{contact.serviceDeskNumber}</a> ({t('contact:localTariff')})
              </p>
            </span>
          </>
        )}

        <h3>{t('footer:sendEmail')}</h3>
        <span className={style.footerSectionItem}>
          <MaterialIcon name="wysiwyg" size={ICON_SIZE} />
          <Link to="/contact"><p>{t('footer:contactForm')}</p></Link>
        </span>
      </FooterSectionColumn>

      <FooterSectionColumn title={t('footer:otherQuestions')}>
        <h3><Link to="/faq">{t('footer:faq')}</Link></h3>
      </FooterSectionColumn>
    </div>
  );
};

export const PreFooter = React.memo(PreFooterComponent);
