import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './LoadingImages.module.scss';
import SquareAnimatedImage from '../SquareAnimatedImage/SquareAnimatedImage';
import { AnimationName } from '../AnimatedImage/AnimatedImage';

export interface ImageItemProp {
  image: AnimationName;
  text: string;
  ariaLabel: string;
}

interface LoadingImagesProps {
  items: ImageItemProp[];
}

const STARTING_INDEX: number = 0;

const LoadingImagesComponent: FC<LoadingImagesProps> = ({ items }) => {
  const [currentImage, setCurrentImage] = useState<ImageItemProp & { index: number } | null>(
    items.length ? { ...items[STARTING_INDEX], index: STARTING_INDEX } : null
  );

  useEffect(() => {
    if (!currentImage || !items.length){
      return;
    }

    const timeout = setTimeout(() => {
      const nextIndex = currentImage.index >= (items.length - 1) ? 0 : (currentImage.index + 1);

      if (nextIndex !== currentImage.index) {
        setCurrentImage({ ...items[nextIndex], index: nextIndex });
      }
    }, 5000);

    return (): void => {
      clearTimeout(timeout);
    };
  }, [currentImage, items]);

  if (!currentImage) {
    return null;
  }

  const containerClassNames = classNames([
    styles.loadingImageContainer,
    items.length > 1 && styles['loadingImageContainer--withFadeOut']
  ]);

  const { index, text, image, ariaLabel } = currentImage;

  return (
    <div className={containerClassNames} key={index + text}>
      <SquareAnimatedImage image={{ type: 'animation', name: image }} ariaLabel={ariaLabel} />
      <h2>{text}</h2>
    </div>
  );
};

export const LoadingImages = React.memo(LoadingImagesComponent);
