import { FC } from 'react';

import styles from './SquareAnimatedImage.module.scss';
import AnimatedImage, { AnimationName } from '../AnimatedImage/AnimatedImage';

type SVGImage = 'error';

const ErrorImage = (
  <svg width="75%" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M73.0801 135C69.9751 135 67.4551 132.48 67.4551 129.375V112.5C67.4551 100.095 77.5501 90 89.9551 90C102.36 90 112.455 100.095 112.455 112.5V129.375C112.455 132.48 109.935 135 106.83 135H73.0801ZM101.205 123.75V112.5C101.205 106.298 96.1576 101.25 89.9551 101.25C83.7526 101.25 78.7051 106.298 78.7051 112.5V123.75H101.205Z" fill="#272D33" />
    <path d="M118.08 84.375C114.975 84.375 112.455 81.855 112.455 78.75V56.25C112.455 53.145 114.975 50.625 118.08 50.625C121.185 50.625 123.705 53.145 123.705 56.25V78.75C123.705 81.855 121.178 84.375 118.08 84.375Z" fill="#272D33" />
    <path d="M61.8301 84.375C58.7251 84.375 56.2051 81.855 56.2051 78.75V56.25C56.2051 53.145 58.7251 50.625 61.8301 50.625C64.9351 50.625 67.4551 53.145 67.4551 56.25V78.75C67.4551 81.855 64.9276 84.375 61.8301 84.375Z" fill="#272D33" />
    <path d="M50.7152 157.5C29.3402 157.47 10.8452 143.7 4.70266 123.232C-1.55984 101.467 -1.55984 78.5325 4.68766 56.835C10.8527 36.3225 29.3402 22.5375 50.7002 22.5H129.195C150.57 22.53 169.058 36.3075 175.2 56.7675C181.47 78.5175 181.478 101.467 175.215 123.173C169.05 143.67 150.563 157.455 129.203 157.5H50.7152ZM50.7227 33.75C34.3502 33.78 20.1902 44.3325 15.4727 60.0075C9.82516 79.6125 9.83266 100.38 15.4877 120.037C20.1902 135.675 34.3577 146.22 50.7227 146.25H129.195C145.553 146.213 159.713 135.667 164.43 119.993C170.085 100.38 170.085 79.605 164.415 59.9475C159.72 44.3175 145.56 33.7725 129.195 33.75H50.7227Z" fill="#BAC3CC" />
  </svg>
);

type SvgImageParams = { type: 'svg'; name: SVGImage };
type AnimationImageParams = { type: 'animation'; name: AnimationName };

interface SquareAnimatedImageProps {
  image: SvgImageParams | AnimationImageParams;
  ariaLabel: string;
}

const svgImages: Record<SVGImage, JSX.Element> = {
  error: ErrorImage
};

const SquareAnimatedImage: FC<SquareAnimatedImageProps> = ({ image, ariaLabel }) => {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.innerContainer} aria-label={ariaLabel} role="img">
        {image.type === 'animation' && (
          <AnimatedImage className={styles.animatedImage} name={image.name} />
        )}
        {image.type === 'svg' && svgImages[image.name]}
      </div>
    </div>
  );
};

export default SquareAnimatedImage;
