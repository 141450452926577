import { MultiLingualStylesheet } from '../interfaces/Stylesheet';
import { CdnRepository } from '../api/interfaces/CdnRepository';
import CdnRepositoryRemote from '../api/CdnRepositoryRemote';

export default class StylesheetService {
  private repository: CdnRepository;

  constructor() {
    this.repository = new CdnRepositoryRemote();
  }

  public async getStylesheet(subscriptionId: string): Promise<MultiLingualStylesheet> {
    const stylesheet = await this.repository.getStylesheet(subscriptionId);

    return stylesheet;
  }
}
